.podcast-wrapper {
    width: 100%;
    margin: 0 auto;
}
  
.podcast-player {
    padding: 24px;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(180deg, #295F98 0%, #1B3D62 6.99%, #1B3D62 7%, #15314F 11.5%, #0D1F32 18.04%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.player-header {
    display: flex;
    gap: 16px;
    align-items: flex-start;
}

.thumbnail {
    width: 96px;
    height: 96px;
    flex-shrink: 0;
    background: #4a4a4a;
    border-radius: 8px;
    overflow: hidden;
}

.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.podcast-info {
    flex: 1;
}

.podcast-title {
    color: white;
    font-weight: bold;
    font-size: 1.125rem;
    margin: 0 0 8px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.podcast-description {
    color: #b3b3b3;
    font-size: 0.875rem;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.player-controls {
    margin-top: 24px;
}

.progress-bar {
    position: relative;
    width: 100%;
    height: 4px;
    background: #4a4a4a;
    border-radius: 2px;
    cursor: pointer;
}

.progress-bar:hover .progress {
    background: #1db954;
}

.progress {
    position: absolute;
    height: 100%;
    background: white;
    border-radius: 2px;
    transition: width 0.1s ease;
}

.progress-bar:hover .progress-handle {
    opacity: 1;
}

.progress-handle {
    position: absolute;
    width: 12px;
    height: 12px;
    background: white;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease;
}

.time-display {
    display: flex;
    justify-content: space-between;
    color: #b3b3b3;
    font-size: 0.75rem;
    margin: 8px 0 16px;
}

.controls-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-controls {
    display: flex;
    align-items: center;
    gap: 24px;
}

.control-button {
    background: none;
    border: none;
    color: #b3b3b3;
    cursor: pointer;
    padding: 0;
    transition: color 0.2s ease;
}

.control-button:hover {
    color: white;
}

.play-button-podcast {
    width: 40px;
    height: 40px;
    background: white;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.play-button-podcast:hover {
    transform: scale(1.05);
}

.right-controls {
    display: flex;
    align-items: center;
    gap: 16px;
}

.speed-button {
    color: #b3b3b3;
    background: none;
    border: 1px solid #b3b3b3;
    border-radius: 12px;
    padding: 4px 8px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.speed-button:hover {
    color: white;
    border-color: white;
}

.volume-control {
    display: flex;
    align-items: center;
    gap: 8px;
}

.volume-slider {
    width: 96px;
    height: 4px;
    background: #4a4a4a;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
}

.volume-level {
    position: absolute;
    height: 100%;
    background: #b3b3b3;
    border-radius: 2px;
    transition: background-color 0.2s ease;
}

.volume-slider:hover .volume-level {
    background: white;
}

/* Next Up Section */
.next-up {
    background: #121212;
    padding: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.next-up-title {
    color: white;
    font-weight: bold;
    margin: 0 0 16px 0;
}

.next-up-item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.next-up-item:hover {
    background: rgba(255, 255, 255, 0.1);
}

.next-up-item:not(:last-child) {
    margin-bottom: 16px;
}

.episode-thumbnail {
    width: 48px;
    height: 48px;
    background: #4a4a4a;
    border-radius: 4px;
    flex-shrink: 0;
}

.episode-info {
    flex: 1;
}

.episode-title {
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0 0 4px 0;
}

.episode-duration {
    color: #b3b3b3;
    font-size: 0.75rem;
    margin: 0;
}