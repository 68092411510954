.contact-button {
    width: 289px;
    height: 71px;
    border-radius: 20px;
    background: var(--black);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center; /* Center text horizontally */
    align-items: center; /* Center text vertically */
    cursor: pointer;
    text-align: center; /* Center text inside flex item */
    margin-top: 15%;
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-button .text {
    color: var(--white);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 15px;
    transition: color 0.3s ease;
}

.contact-button .send-email-icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    transition: transform 0.3s ease;
}

/* Hover Effect */
.contact-button:hover {
    background: var(--black);
    transform: scale(1.05); /* Slight scaling effect */
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5); /* Enhanced shadow */
}

.contact-button:hover .text {
    color: var(--white); /* Invert text color on hover */
}

.contact-button:hover .send-email-icon {
    transform: translateY(-2px); /* Slight upward movement */
}
