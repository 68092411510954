.button {
    width: 160px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #121212;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;               /* Use Flexbox */
    align-items: center;         /* Center items vertically */
    justify-content: center;     /* Center items horizontally */
    gap: 8px;                    /* Space between icon and text */
    cursor: pointer;
}

.button:hover {
    transform: scale(1.1);
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}
  
.link {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}
  
.read-more-icon {
    width: 24px;                 /* Adjust icon size if necessary */
    height: 24px;
}
  
  
.button .link {
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
  