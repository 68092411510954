@import url(https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --black: #121212;
  --white: #FFFF;
}

* {
  font-family: "Hanken Grotesk", sans-serif;
}

.footer-container{
    background: url('/img/footer bg.jpg') no-repeat center center fixed;
    background-size: cover;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    padding: 90px 90px 20px 90px;
}

.footer-container .menu-socmend-info-wrapper {
    display: flex;
    justify-content: space-between;
}

.footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper {
    align-items: center;
}

.footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper .social-media {
    display: flex;
    grid-gap: 10px;
    gap: 10px; /* Adjust gap as needed */
    margin-bottom: 5%;
}

.footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper .social-media .icon:hover {
    transform: scale(1.1)
}

.footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper .menu {
    display: flex;
    grid-gap: 20px;
    gap: 20px; /* Space between menu items */
}

.footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper .menu .menu-text {
    color: var(--white);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
}

.footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper .menu .menu-text:hover {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

.footer-container .menu-socmend-info-wrapper .information-wrapper .logo-kelanara {
    margin-left: 8%;
    margin-bottom: 3%;
}

.footer-container .menu-socmend-info-wrapper .information-wrapper .information {
    display: flex;
    grid-gap: 70px;
    gap: 70px;
}

.footer-container .menu-socmend-info-wrapper .information-wrapper .information .address .title {
    color: var(--white);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.footer-container .menu-socmend-info-wrapper .information-wrapper .information .address .the-address {
    color: var(--white);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footer-container .menu-socmend-info-wrapper .information-wrapper .information .contact .title {
    color: var(--white);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.footer-container .menu-socmend-info-wrapper .information-wrapper .information .contact .the-contact {
    color: var(--white);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footer-container .line {
    width: 1300px;
    height: 1px; 
    background: #888; 
    margin-top: 100px;
}

.footer-container .copyright .text {
    color: var(--white);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
}

@media screen and (max-width: 1024px) {
    .footer-container {
        padding: 60px 40px 20px 40px;
    }

    .footer-container .line {
        width: 100%;
        margin-top: 60px;
    }

    .footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper .menu {
        grid-gap: 15px;
        gap: 15px;
    }

    .footer-container .menu-socmend-info-wrapper .information-wrapper .information {
        grid-gap: 40px;
        gap: 40px;
    }

    .footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper .menu .menu-text {
        font-size: 18px;
    }

    .footer-container .menu-socmend-info-wrapper .information-wrapper .information .address .title,
    .footer-container .menu-socmend-info-wrapper .information-wrapper .information .contact .title,
    .footer-container .menu-socmend-info-wrapper .information-wrapper .information .address .the-address,
    .footer-container .menu-socmend-info-wrapper .information-wrapper .information .contact .the-contact {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .footer-container {
        padding: 40px 20px 20px 20px;
    }

    .footer-container .menu-socmend-info-wrapper {
        flex-direction: column;
        grid-gap: 40px;
        gap: 40px;
    }

    .footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper {
        order: 2;
    }

    .footer-container .menu-socmend-info-wrapper .information-wrapper {
        order: 1;
    }

    .footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper .menu {
        flex-wrap: wrap;
        justify-content: center;
    }

    .footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper .social-media {
        justify-content: center;
        margin-bottom: 20px;
    }

    .footer-container .menu-socmend-info-wrapper .information-wrapper .logo-kelanara {
        display: none;
    }

    .footer-container .copyright .text {
        text-align: center;
    }
}

/* For mobile phones */
@media screen and (max-width: 480px) {
    .footer-container .menu-socmend-info-wrapper .information-wrapper .information {
        flex-direction: column;
        grid-gap: 30px;
        gap: 30px;
        text-align: center;
    }

    .footer-container .menu-socmend-info-wrapper .menu-socmed-wrapper .menu .menu-text {
        font-size: 16px;
    }

    .footer-container .menu-socmend-info-wrapper .information-wrapper .information .address .title,
    .footer-container .menu-socmend-info-wrapper .information-wrapper .information .contact .title,
    .footer-container .menu-socmend-info-wrapper .information-wrapper .information .address .the-address,
    .footer-container .menu-socmend-info-wrapper .information-wrapper .information .contact .the-contact {
        font-size: 16px;
    }

    .footer-container .copyright .text {
        font-size: 14px;
    }

    .footer-container .menu-socmend-info-wrapper .information-wrapper .logo-kelanara {
        display: none;
    }
}

.button {
    width: 160px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #121212;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;               /* Use Flexbox */
    align-items: center;         /* Center items vertically */
    justify-content: center;     /* Center items horizontally */
    grid-gap: 8px;
    gap: 8px;                    /* Space between icon and text */
    cursor: pointer;
}

.button:hover {
    transform: scale(1.1);
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}
  
.link {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}
  
.read-more-icon {
    width: 24px;                 /* Adjust icon size if necessary */
    height: 24px;
}
  
  
.button .link {
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
  
:root {
  --black: #121212;
  --white: #FFFF;
}

* {
  font-family: "Hanken Grotesk", sans-serif;
}

/* Home.css */

/* Setting the background image for the entire home page */
.home-container {
  background: url('/img/home bg.jpg') no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.home-headline {
  display: flex;               /* Use flexbox */
  flex-direction: column;      /* Stack children vertically */
  justify-content: center;     /* Center vertically */
  height: 100vh;              /* Full viewport height */
  margin-left: 10%;
}

.home-headline .title {
  font-weight: bold;
  color: rgba(255, 255, 255, 0.7);
  font-size: 70px;
}

.home-headline .slogan {
  font-weight: bold;
  color: rgba(140, 121, 121, 0.7);
  font-size: 40px;
}

.home-about {
  margin-top: 20%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20%;
  display: flex;                /* Enable Flexbox for the main container */
  justify-content: space-between; /* Space out items evenly */
  align-items: flex-start;          /* Vertically align the items */
  padding: 20px;                /* Optional: Add padding around the container */
  grid-gap: 50px;
  gap: 50px;                    /* Optional: Space between the two containers */
}

.home-about .title {
  color: var(--black);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-about .description {
  color: var(--black);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-about .title-container {
  flex: 1 1;                      /* Allow title-container to take equal space */
}

.home-about .description-container {
  flex: 2 1;                      /* Allow description-container to take more space */
  display: flex;
  flex-direction: column;    /* Stack the paragraphs vertically */
  grid-gap: 15px;
  gap: 15px;                 /* Add gap between the paragraphs */
}

.home-video {
  background-color: var(--black); /* Background color for the video section */
  padding-top: 20px; /* Optional padding to make the border more visible */
  text-align: center; /* Center content if needed */
  padding-bottom: 20px;
}

.home-video .video-wrapper {
  position: relative;
  width: 70%;
  margin: 5% auto 3% auto;
}

.home-video .video-wrapper .thumbnail-container .video-thumbnail {
  border: 2px solid #ffffff; 
  width: 100%;
  height: 35rem;
  box-sizing: border-box; 
  pointer-events: none;
}

.home-video .intro {
    border: 2px solid #ffffff; 
    width: 100%;
    height: 35rem;
    box-sizing: border-box; 
    pointer-events: none;
}

.intro.playing {
  pointer-events: auto;
}

.home-video .video-wrapper .thumbnail-container .play-button {
  position: absolute;
  top: 35%;
  left: 50%; 
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 10;
}

.home-video .video-wrapper .thumbnail-container .play-button .play-icon {
  width: 80px;
  height: 80px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.home-video .video-wrapper .thumbnail-container .play-button:hover .play-icon {
  opacity: 1;
}

.home-video .bottom-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.home-video .bottom-wrapper .text-wrapper {
  left: 0;
  margin-top: 2%;
  text-align: left;
  flex-grow: 1; 
}

.home-video .bottom-wrapper .text-wrapper .latest-video {
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  grid-gap: 10px; 
  gap: 10px;
}

.home-video .bottom-wrapper .text-wrapper .latest-video .youtube-play-button-icon {
  width: 40px;  /* Adjust size as needed */
  height: 40px;
}

.home-video .bottom-wrapper .text-wrapper .video-text {
  color: var(--white);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 10px;
}

.home-video .bottom-wrapper .text-wrapper .video-text-secondary {
  color: var(--white);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 10px;
  margin-right: 470px;
}

.home-video .bottom-wrapper .carousel-controls {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 15px;
  align-self: flex-start;
}

.home-video .bottom-wrapper .carousel-controls .carousel-dot {
  width: 53px;
  height: 2px;
  outline: none;
  border: none;
  transition: background-color 0.3s ease;
}

.home-video .bottom-wrapper .carousel-controls .carousel-dot.active {
  background-color:#fff !important;
  box-shadow: 
  0 0 20px rgba(255, 255, 255, 0.9),
  0 0 35px rgba(255, 255, 255, 0.8),
  0 0 45px rgba(255, 255, 255, 0.6);
}

.home-video .bottom-wrapper .carousel-controls .carousel-dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}

.featured-projects {
  padding: 10px;
  margin-bottom: 10%;
}

.featured-projects .title .text {
  color: var(--black);
  font-family: "Hanken Grotesk";
  font-size: 50px;
  font-weight: 700;
  margin-top: 10%;
  margin-left: 15%;
  margin-bottom: 5%;
}

.featured-projects .projects-grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 7px;
  gap: 7px;
  justify-content: center;
  align-items: center;
}

.featured-projects .projects-grid .project-item {
  position: relative; /* Agar overlay dapat diposisikan relatif terhadap item */
  overflow: hidden;  /* Menghindari elemen yang melampaui batas */
}

.featured-projects .projects-grid .project-item .project-image {
  width: 456px;
  height: 257px;
  object-fit: cover; 
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease; /* Menambahkan transisi saat hover */
}

.featured-projects .projects-grid .project-item:hover .project-image {
  transform: scale(1.05); /* Zoom sedikit saat hover */
}

.featured-projects .projects-grid .project-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.70);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Mulai dengan tidak terlihat */
  transition: opacity 0.3s ease; /* Transisi untuk efek opacity */
}

.featured-projects .projects-grid .project-item:hover .overlay {
  opacity: 1; /* Menampilkan overlay saat hover */
}

.featured-projects .projects-grid .project-item .project-title {
  color: var(--white);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.featured-projects .projects-grid .project-item .see-now {
  color: var(--white);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5%;
  margin-right: 20px;
}

.featured-projects .projects-grid .project-item .see-now .icon {
  width: 20px; /* Ganti dengan ukuran ikon yang diinginkan */
  height: 20px;
  background: url('/path/to/icon.png') no-repeat center; /* Ganti dengan URL ikon */
  background-size: contain; /* Sesuaikan ukuran ikon */
  margin-right: 5px; /* Jarak antara ikon dan teks */
}

.clients {
  margin-bottom: 10%;
}

.clients .title .text {
  color: var(--black);
  font-family: "Hanken Grotesk";
  font-size: 50px;
  font-weight: 700;
  margin-top: 10%;
  margin-left: 15%;
  margin-bottom: 5%;
}

.clients .client-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.clients .client-logos .row-top {
  margin-bottom: 5%;
}

.clients .client-logos .row-top,
.clients .client-logos .row-bottom {
  display: flex;
  justify-content: center;
  grid-gap: 180px;
  gap: 180px;
}

.clients .client-logos .client-logo {
  width: 200px;
  height: auto;
  filter: grayscale(100%) !important; /* Mengubah saturasi */
  transition: filter 0.3s ease, transform 0.3s ease; /* Transisi halus */
}

.clients .client-logos .client-logo:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}

.news {
  margin-bottom: 10%;
  padding: 10px;
}

.news .all-news {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  grid-gap: 50px; 
  gap: 50px; 
  margin-left: 15%;
  margin-right: 15%;
}

.news .all-news .news-item {
  display: flex;
  flex-direction: column;
  text-decoration:none
}

.news .title .text {
  color: var(--black);
  font-family: "Hanken Grotesk";
  font-size: 50px;
  font-weight: 700;
  margin-top: 10%;
  margin-left: 15%;
  margin-bottom: 5%;
}

.news .all-news .news-item .image {
  width: 339px;
  height: 226px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  object-fit: cover;
}

.news .all-news .news-item .image:hover {
  transform: scale(1.1)
}

.news .all-news .news-item .date {
  color: #888;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
}

.news .all-news .news-item .content .title {
  color: var(--black);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5%;
}

.news .all-news .news-item .content .desc {
  color: var(--black);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.about-container {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; /* This ensures the pseudo-element stays contained */
}

.about-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('/img/about bg.jpg') no-repeat center center fixed;
  background-size: cover;
  filter: saturate(50%) brightness(70%);
  z-index: -1;
}

.about {
  margin-left: 8%;
  margin-right: 8%;
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex;                /* Enable Flexbox for the main container */
  justify-content: space-between; /* Space out items evenly */
  align-items: flex-start;          /* Vertically align the items */
  padding: 5px;                /* Optional: Add padding around the container */
  grid-gap: 20%;
  gap: 20%;                    /* Optional: Space between the two containers */
}

.about .title {
  color: var(--black);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.about .description {
  color: var(--black);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.expertise {
  background: var(--black);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;
}

.expertise .expertise-wrapper {
  margin-left: 8%;
  margin-right: 8%;
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex; 
  align-items: flex-start; 
  grid-gap: 20px; 
  gap: 20px;
  padding: 20px;
}

.expertise .expertise-wrapper .text {
  margin-right: 20%;
}


.expertise .expertise-wrapper .text .title {
  color: var(--white);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.expertise .expertise-wrapper .all-expertise {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  gap: 40px;
}

.expertise .expertise-wrapper .expert-title, 
.expertise .expertise-wrapper .expert-desc {
  color: var(--white);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.expertise .expertise-wrapper .expert-desc {
  margin-top: 15px;
}

.folks {
  padding: 10%;
}

.folks .text {
  color: var(--black);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 100px;
}

.folks .all-folks {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  gap: 40px;
}

.folks .all-folks .folk-item .image {
  transition: transform 0.3s ease;
}

.folks .all-folks .folk-item .image:hover {
  transform: scale(1.1);
}

.folks .all-folks .folk-item .folk-title {
  color: var(--black);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-top: 20px;
}

.project-wrapper {
  background: #3C3D37;
  padding: 20px;
}

.project-wrapper .projects-grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 7px;
  gap: 7px;
  justify-content: center;
  align-items: center;
}

.project-wrapper .projects-grid .project-item {
  position: relative; /* Agar overlay dapat diposisikan relatif terhadap item */
  overflow: hidden;  /* Menghindari elemen yang melampaui batas */
}

.project-wrapper .projects-grid .project-item .project-image {
  width: 456px;
  height: 257px;
  object-fit: cover; 
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease; /* Menambahkan transisi saat hover */
}

.project-wrapper .projects-grid .project-item:hover .project-image {
  transform: scale(1.05); /* Zoom sedikit saat hover */
}

.project-wrapper .projects-grid .project-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.70);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Mulai dengan tidak terlihat */
  transition: opacity 0.3s ease; /* Transisi untuk efek opacity */
}

.project-wrapper .projects-grid .project-item:hover .overlay {
  opacity: 1; /* Menampilkan overlay saat hover */
}

.project-wrapper .projects-grid .project-item .project-title {
  color: var(--white);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.project-wrapper .projects-grid .project-item .see-now {
  color: var(--white);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5%;
  margin-right: 20px;
}

.project-wrapper .projects-grid .project-item .see-now .icon {
  width: 20px; /* Ganti dengan ukuran ikon yang diinginkan */
  height: 20px;
  background: url('/path/to/icon.png') no-repeat center; /* Ganti dengan URL ikon */
  background-size: contain; /* Sesuaikan ukuran ikon */
  margin-right: 5px; /* Jarak antara ikon dan teks */
}

.media-container {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; /* This ensures the pseudo-element stays contained */
}

.media-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('/img/news bg.jpg') no-repeat center center fixed;
  background-size: cover;
  filter: saturate(50%) brightness(70%);
  z-index: -1;
}

.youtube-video {
  background-color: var(--black); /* Background color for the video section */
  padding-top: 10px; /* Optional padding to make the border more visible */
  text-align: center; /* Center content if needed */
  padding-bottom: 30px;
}

.youtube-video .video-wrapper {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.youtube-video .video-wrapper .thumbnail-container .video-thumbnail {
  width: 100%;
  height: 644px;
  box-sizing: border-box; 
  pointer-events: none;
}

.youtube-video .intro {
    border: 2px solid #ffffff; 
    width: 100%;
    height: 35rem;
    box-sizing: border-box; 
    pointer-events: none;
}

.youtube-video .video-wrapper .intro.playing {
  pointer-events: auto;
}

.youtube-video .video-wrapper .thumbnail-container .play-button {
  position: absolute;
  top: 40%;
  left: 50%; 
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 10;
}

.youtube-video .video-wrapper .thumbnail-container .play-button .play-icon {
  width: 80px;
  height: 80px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.youtube-video .video-wrapper .thumbnail-container .play-button:hover .play-icon {
  opacity: 1;
}

.youtube-video .bottom-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.youtube-video .bottom-wrapper .text-wrapper {
  left: 0;
  margin-top: 2%;
  text-align: left;
  flex-grow: 1; 
  margin-left: 12%;
}

.youtube-video .bottom-wrapper .text-wrapper .latest-video {
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  grid-gap: 10px; 
  gap: 10px;
}

.youtube-video .bottom-wrapper .text-wrapper .latest-video .youtube-play-button-icon {
  width: 40px;  /* Adjust size as needed */
  height: 40px;
}

.youtube-video .bottom-wrapper .text-wrapper .video-text {
  color: var(--white);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 10px;
}

.youtube-video .bottom-wrapper .text-wrapper .video-text-secondary {
  color: var(--white);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 10px;
  margin-right: 470px;
}

.youtube-video .bottom-wrapper .carousel-controls {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 15px;
  align-self: flex-start;
}

.youtube-video .bottom-wrapper .carousel-controls .carousel-dot {
  width: 53px;
  height: 2px;
  outline: none;
  border: none;
  transition: background-color 0.3s ease;
}

.youtube-video .bottom-wrapper .carousel-controls .carousel-dot.active {
  background-color:#fff !important;
  box-shadow: 
  0 0 20px rgba(255, 255, 255, 0.9),
  0 0 35px rgba(255, 255, 255, 0.8),
  0 0 45px rgba(255, 255, 255, 0.6);
}

.youtube-video .bottom-wrapper .carousel-controls .carousel-dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}

.news .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: "Hanken Grotesk", sans-serif;
}

.news .pagination .pagination-button {
  background-color: transparent;
  color: #121212;
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  padding: 8px;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  margin: 0 8px;
  position: relative;
}

.news .pagination .pagination-button.active {
  background-color: #121212; /* Dark background for active button */
  color: #FFF; /* White text for active button */
  border-radius: 50%; /* Make it round */
  width: 40px; /* Width and height to create circular shape */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.1); /* Slightly larger active button */
}

/* Right arrow styling for the Next button */
.news .pagination .pagination-button.next-button {
  display: flex;
  align-items: center;
}

.news .pagination .pagination-button .right-arrow {
  margin-left: 5px;
}

/* Glow effect for regular pagination buttons on hover */
.news .pagination .pagination-button:not(.active):hover {
  color: #121212;
  text-shadow: 0 0 8px rgba(18, 18, 18, 0.7), 0 0 15px rgba(18, 18, 18, 0.7);
  transform: scale(1.05); /* Slight zoom effect for extra emphasis */
  transition: text-shadow 0.3s ease, transform 0.3s ease;
}

/* Animation for buttons on load */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.news .pagination .pagination-button {
  animation: fadeIn 0.3s ease;
}

/* Disabled state for the Next button */
.news .pagination .pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.podcast {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 7%;
  padding-right: 7%;
  background: #121212;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.contact-wrapper {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; /* This ensures the pseudo-element stays contained */
}

.contact-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('/img/contact bg.jpg') no-repeat center center fixed;
  background-size: cover;
  filter: saturate(50%) brightness(70%);
  z-index: -1;
}

.form {
  background-color: var(--white);
  margin: 10%;
  padding: 20px;
}

.form .row {
  display: flex;
  grid-gap: 50px;
  gap: 50px; /* Adjust gap as needed */
  margin-bottom: 15px; /* Add spacing between rows if needed */
}

.form .row .col {
  flex: 1 1; /* Ensures both inputs in the row take up equal width */
}

.form .form-control {
  border: none; /* Remove default border */
  border-bottom: 2px solid #000; /* Add bottom border */
  background: transparent; /* Transparent background */
  outline: none; /* Remove outline on focus */
  padding: 10px 0; /* Adjust padding for better spacing */
  font-size: 18px;
  font-weight: 700;
  color: #121212; /* Text color */
  border-radius: 0; /* Remove border radius */
  width: 100%; /* Make input take full width of its container */
  max-width: 500px; /* Adjust max width as needed */
}

.form .form-input .col {
  flex: 1 1; /* Optional: Ensures both columns in the row take up equal width */
}

.form .form-control::placeholder {
  color: #888;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 1; /* Ensure the placeholder is fully opaque */
}

.form .form-control:focus {
  border-bottom-color: #000; /* Bottom border remains solid */
  transition: border-color 0.3s ease; /* Smooth transition */
}

.form .form-info-wrapper {
  display: flex;                /* Enable Flexbox for the main container */
  /* justify-content: space-between;  */
  align-items: flex-start;          /* Vertically align the items */
}

.form .form-info-wrapper .form-input {
  margin-right: 15%;
}

.form .form-info-wrapper .form-input .cta {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15%;
}

.form .form-info-wrapper .form-input .desc {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10%;
}

.form .form-info-wrapper .org-info {
  margin-top: 15%;
}

.form .form-info-wrapper .org-info .whatsapp-contact,
.form .form-info-wrapper .org-info .email-contact
{
  margin-bottom: 20%;
}

.form .form-info-wrapper .org-info .text {
  color: var(--black);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form .form-info-wrapper .org-info .socmed-icon .social-media {
  display: flex;
  grid-gap: 15px;
  gap: 15px; /* Atur jarak horizontal antar ikon */
}

.form .form-info-wrapper .org-info .socmed-icon .social-media .icon {
  width: 30px; /* Sesuaikan ukuran ikon */
  height: 30px;
  transition: transform 0.2s; /* Tambahkan efek hover */
}

.form .form-info-wrapper .org-info .socmed-icon .social-media .icon:hover {
  transform: scale(1.1); /* Sedikit memperbesar ikon saat hover */
}

.project-container {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; /* This ensures the pseudo-element stays contained */
}

.project-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('/img/project bg.jpg') no-repeat center center fixed;
  background-size: cover;
  filter: saturate(50%) brightness(70%);
  z-index: -1;
}

.all-project-wrapper {
  background-color: var(--white);
  padding: 5%;
}

.all-project-wrapper .title {
  color: var(--black);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.all-project-wrapper .back-home-route {
  display: flex;           /* Use flexbox for alignment */
  align-items: center;     /* Center icon and text vertically */
  margin-top: 20px;       /* Add space between the title and back home */
}

.all-project-wrapper .back-home {
  text-decoration: none;    /* Remove underline from link */
  color: var(--black);      /* Text color */
  font-size: 20px;          /* Font size */
  font-style: normal;       /* Font style */
  font-weight: 400;         /* Font weight */
  line-height: normal;      /* Line height */
  margin-left: 8px;        /* Add space between icon and text */
}

.all-project-wrapper .back-home-icon {
  width: 10px;             /* Set icon width */
  height: 10px;            /* Set icon height */
}


.all-project-wrapper .back-home-route:hover {
  color: var(--primary-color); /* Change color on hover (replace with your desired color) */
  transform: scale(1.05);      /* Slightly scale up the text */
}

.all-project-grid {
  padding-left: 50px;
  padding-right: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates a 3-column layout */
  grid-gap: 10px;
  gap: 10px; /* Space between grid items */
}

.all-project-grid .project-item .project-image {
  width: 456px;
  height: 257px;
  object-fit: cover; 
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease; /* Menambahkan transisi saat hover */
}

.all-project-grid .project-item .project-title {
  margin-top: 2%;
  margin-bottom: 5%;
  color: var(--black);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.all-project-grid .project-item:hover .project-image {
  transform: scale(1.05); /* Scale image slightly on hover */
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25); /* Increase shadow on hover */
}

.project-clients {
  background: var(--black);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5%;
}

.project-clients .project-clients-wrapper {
  margin-bottom: 10%;
  display: flex;
  grid-gap: 10%;
  gap: 10%;
}


.project-clients .project-clients-wrapper  .title {
  color: var(--white);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.project-clients .project-clients-wrapper  .all-clients .item .client-name {
  color: var(--white);
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5%;
}

.news-details-container {
  background-color: var(--black);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.news-details-wrapper .news-details {
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
}

.news-details-wrapper .back-home-route {
  display: flex;           /* Use flexbox for alignment */
  align-items: center;     /* Center icon and text vertically */
  margin-bottom: 20px;
}

.news-details-wrapper .back-home-route:hover {
  color: var(--primary-color); /* Change color on hover (replace with your desired color) */
  transform: scale(1.05);      /* Slightly scale up the text */
}

.news-details-wrapper .back-home {
  text-decoration: none;    /* Remove underline from link */
  color: var(--black);      /* Text color */
  font-size: 20px;          /* Font size */
  font-style: normal;       /* Font style */
  font-weight: 400;         /* Font weight */
  line-height: normal;      /* Line height */
  margin-left: 8px;        /* Add space between icon and text */
}

.news-details-wrapper .news-details .flex-wrapper {
  display: flex;
  grid-gap: 5%;
  gap: 5%;
  align-items: flex-start; /* Aligns items to the top */
  flex-wrap: wrap; /* Allows wrapping if necessary */
}

.news-details-wrapper .news-details .flex-wrapper .details-image {
  flex-shrink: 0; /* Prevent the image from shrinking */
  max-width: 300px; /* Limit the image's maximum width */
  height: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensure the image doesn't stretch */
}

.news-details-wrapper .news-details .flex-wrapper .details {
  margin-top: 50px;
  flex: 1 1; /* Allow the details to take up remaining space */
  margin-top: 0; /* Align with the image */
  word-break: break-word; /* Ensure long text wraps */
}

.news-details-wrapper .news-details .flex-wrapper .details .title {
  color: var(--black);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 50px;
}

.news-details-wrapper .news-details .flex-wrapper .details .published {
  color: gray;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}

.news-details-wrapper .news-details .flex-wrapper .details .desc {
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}

.project-details-container {
  background-color: var(--black);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.project-details-wrapper .project-details {
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
}

.project-details-wrapper .back-home-route {
  display: flex;           /* Use flexbox for alignment */
  align-items: center;     /* Center icon and text vertically */
  margin-bottom: 20px;
}

.project-details-wrapper .back-home-route:hover {
  color: var(--primary-color); /* Change color on hover (replace with your desired color) */
  transform: scale(1.05);      /* Slightly scale up the text */
}

.project-details-wrapper .back-home {
  text-decoration: none;    /* Remove underline from link */
  color: var(--black);      /* Text color */
  font-size: 20px;          /* Font size */
  font-style: normal;       /* Font style */
  font-weight: 400;         /* Font weight */
  line-height: normal;      /* Line height */
  margin-left: 8px;        /* Add space between icon and text */
}

.project-details-wrapper .project-details .flex-wrapper .image-wrapper .details-image {
  width: 500px;
  height: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}

.project-details-wrapper .project-details .flex-wrapper .image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.project-details-wrapper .project-details .flex-wrapper .details .title {
  color: var(--black);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 50px;
}

.project-details-wrapper .project-details .flex-wrapper .details .desc,
.project-details-wrapper .project-details .flex-wrapper .details .sinopsis {
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}

@media (max-width: 1024px) {
  /* Home About Section */
  .home-about {
    flex-direction: column;
    margin: 10%;
    grid-gap: 30px;
    gap: 30px;
  }

  /* Featured Projects Grid */
  .featured-projects .projects-grid,
  .project-wrapper .projects-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    gap: 20px;
  }

  /* Client Logos */
  .clients .client-logos .row-top,
  .clients .client-logos .row-bottom {
    grid-gap: 40px;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
  }

  /* News Grid */
  .news .all-news {
    grid-template-columns: repeat(2, 1fr);
    margin: 5%;
  }
}

/* For Tablet  */
@media (max-width: 768px) {
  .home-headline .title {
    font-size: 40px;
  }
  
  .home-headline .slogan {
    font-size: 24px;
  }
  
  .home-about .title,
  .expertise .expertise-wrapper .text .title,
  .folks .text,
  .featured-projects .title .text,
  .clients .title .text,
  .news .title .text {
    font-size: 32px;
  }
  
  .home-about .description {
    font-size: 20px;
  }

  .featured-projects .projects-grid .project-item .project-image {
    width: 257px;
    height: 257px;
    object-fit: cover; 
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease; /* Menambahkan transisi saat hover */
    display: cover;
  }
  
  .featured-projects .projects-grid .project-item:hover .project-image {
    transform: scale(1.05); /* Zoom sedikit saat hover */
  }

  .home-video .bottom-wrapper .text-wrapper .video-text {
    font-size: 20px;
  }

  .home-video .bottom-wrapper .text-wrapper .video-text-secondary {
    font-size: 15px;
    margin-right: 270px;
  }

  .home-video .bottom-wrapper .text-wrapper .latest-video {
    grid-gap: 5px;
    gap: 5px;
  }

  /* Video Section */
  .home-video .video-wrapper,
  .youtube-video .video-wrapper {
    width: 90%;
  }

  .home-video .video-wrapper .thumbnail-container .video-thumbnail,
  .youtube-video .video-wrapper .thumbnail-container .video-thumbnail {
    height: 250px;
  }

  /* Featured Projects Grid */
  .featured-projects .projects-grid,
  .project-wrapper .projects-grid {
    grid-template-columns: 1fr;
  }

  /* News Grid */
  .news .all-news {
    grid-template-columns: 1fr;
  }

  .news .all-news .news-item .image {
    width: 100%;
    height: auto;
  }

  /* Expertise Section */
  .expertise .expertise-wrapper {
    flex-direction: column;
    margin: 5%;
  }

  .expertise .expertise-wrapper .all-expertise {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    gap: 20px;
  }

  /* Folks Section */
  .folks .all-folks {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    gap: 20px;
  }

  .all-project-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
  }

  .all-project-grid .project-item .project-image {
    width: 100%;
    height: auto;
  } 

  .form .form-info-wrapper {
    flex-direction: column;
  }

  .form .row {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }

  .form .form-control {
    max-width: 100%;
  }

  .form .form-info-wrapper .form-input {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .home-headline {
    margin-left: 5%;
    text-align: center;
  }

  .all-project-grid {
    grid-template-columns: 1fr;
  }

  .project-clients .project-clients-wrapper {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
}

/* For Mobile Phone */
@media (max-width: 480px) {
  .home-video .bottom-wrapper .text-wrapper .video-text,
  .youtube-video .bottom-wrapper .text-wrapper .video-text {
    font-size: 24px;
  }

  .home-video .bottom-wrapper .text-wrapper .video-text-secondary,
  .youtube-video .bottom-wrapper .text-wrapper .video-text-secondary {
    font-size: 18px;
    margin-right: 0;
  }

  .folks .all-folks {
    grid-template-columns: 1fr;
  }

  .pagination .pagination-button {
    font-size: 18px;
  }

  .featured-projects .projects-grid .project-item .project-image {
    width: 300px;
    height: 300px;
    object-fit: cover; 
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease; 
    display: cover;
  }
  
  .featured-projects .projects-grid .project-item:hover .project-image {
    transform: scale(1.05); 
  }
}

@media (hover: none) {
  .featured-projects .projects-grid .project-item .overlay,
  .project-wrapper .projects-grid .project-item .overlay {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
  }
}
.custom-navbar {
    border: none;
    box-shadow: none;
    transition: background-color 0.3s ease;
    top: 20px;
    left: 0;
    right: 0;
    padding: 20px 0;
    background: transparent;
    position: relative;
    z-index: 1000;
}

/* Navbar logo styling */
.navbar-brand-logo {
    height: 40px;
    width: auto;
}

/* Ensure the container is properly styled */
.custom-navbar .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

/* Main navigation links */
.navbar-nav {
    display: flex !important;
    align-items: center;
}

/* Centered navigation links styling (applies underline only to main links) */
.navbar-nav > .nav-link:not(.language-toggle .nav-link) {
    position: relative;
    margin: 0 1rem;
    padding-bottom: 5px;
    color: #ffffff;
    transition: color 0.3s ease;
}

/* Underline effect for main nav links only */
.navbar-nav > .nav-link:not(.language-toggle .nav-link)::after {
    content: ''; 
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.8);
    transform: scaleX(0); /* Start with no width */
    transform-origin: right;
    transition: transform 0.3s ease;
}

/* Show underline on hover and active state for main nav links only */
.navbar-nav > .nav-link:not(.language-toggle .nav-link):hover::after,
.navbar-nav > .nav-link:not(.language-toggle .nav-link).active::after {
    transform: scaleX(1); /* Show full underline */
    transform-origin: left;
}

/* Glow effect for active main navigation links */
.navbar-nav .nav-link.active.glow {
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.7), 0 0 15px #FFD700;
    color: #f8f9fa !important;
}

/* Language toggle container adjustments */
.language-toggle {
    display: flex;
    align-items: center;
}

/* Language separator styling */
.language-toggle .language-separator {
    width: 2px;
    height: 39px;
    background-color: #ffffff;
    margin: 0 0.5rem;
}

/* Language toggle link styling without underline */
.language-toggle .nav-link {
    color: #ffffff;
    cursor: pointer;
    padding: 0 5px;
    transition: color 0.3s ease, text-shadow 0.3s ease;
}

/* Glow effect for active language toggle without underline */
.language-toggle .nav-link.active.glow {
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.7), 0 0 15px #FFD700;
    color: #f8f9fa !important;
}

/* Hover effect for language toggle links */
.language-toggle .nav-link:hover {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
    color: #ffffff;
}

/* Hover effect for regular links */
.navbar-nav .nav-link:hover {
    color: #fff !important;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

/* Media query to hide the language toggle on smaller screens */
@media (max-width: 768px) {
    .language-toggle {
        display: none !important;
    }
}
.podcast-wrapper {
    width: 100%;
    margin: 0 auto;
}
  
.podcast-player {
    padding: 24px;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(180deg, #295F98 0%, #1B3D62 6.99%, #1B3D62 7%, #15314F 11.5%, #0D1F32 18.04%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.player-header {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    align-items: flex-start;
}

.thumbnail {
    width: 96px;
    height: 96px;
    flex-shrink: 0;
    background: #4a4a4a;
    border-radius: 8px;
    overflow: hidden;
}

.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.podcast-info {
    flex: 1 1;
}

.podcast-title {
    color: white;
    font-weight: bold;
    font-size: 1.125rem;
    margin: 0 0 8px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.podcast-description {
    color: #b3b3b3;
    font-size: 0.875rem;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.player-controls {
    margin-top: 24px;
}

.progress-bar {
    position: relative;
    width: 100%;
    height: 4px;
    background: #4a4a4a;
    border-radius: 2px;
    cursor: pointer;
}

.progress-bar:hover .progress {
    background: #1db954;
}

.progress {
    position: absolute;
    height: 100%;
    background: white;
    border-radius: 2px;
    transition: width 0.1s ease;
}

.progress-bar:hover .progress-handle {
    opacity: 1;
}

.progress-handle {
    position: absolute;
    width: 12px;
    height: 12px;
    background: white;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease;
}

.time-display {
    display: flex;
    justify-content: space-between;
    color: #b3b3b3;
    font-size: 0.75rem;
    margin: 8px 0 16px;
}

.controls-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-controls {
    display: flex;
    align-items: center;
    grid-gap: 24px;
    gap: 24px;
}

.control-button {
    background: none;
    border: none;
    color: #b3b3b3;
    cursor: pointer;
    padding: 0;
    transition: color 0.2s ease;
}

.control-button:hover {
    color: white;
}

.play-button-podcast {
    width: 40px;
    height: 40px;
    background: white;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.play-button-podcast:hover {
    transform: scale(1.05);
}

.right-controls {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.speed-button {
    color: #b3b3b3;
    background: none;
    border: 1px solid #b3b3b3;
    border-radius: 12px;
    padding: 4px 8px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.speed-button:hover {
    color: white;
    border-color: white;
}

.volume-control {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.volume-slider {
    width: 96px;
    height: 4px;
    background: #4a4a4a;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
}

.volume-level {
    position: absolute;
    height: 100%;
    background: #b3b3b3;
    border-radius: 2px;
    transition: background-color 0.2s ease;
}

.volume-slider:hover .volume-level {
    background: white;
}

/* Next Up Section */
.next-up {
    background: #121212;
    padding: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.next-up-title {
    color: white;
    font-weight: bold;
    margin: 0 0 16px 0;
}

.next-up-item {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.next-up-item:hover {
    background: rgba(255, 255, 255, 0.1);
}

.next-up-item:not(:last-child) {
    margin-bottom: 16px;
}

.episode-thumbnail {
    width: 48px;
    height: 48px;
    background: #4a4a4a;
    border-radius: 4px;
    flex-shrink: 0;
}

.episode-info {
    flex: 1 1;
}

.episode-title {
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0 0 4px 0;
}

.episode-duration {
    color: #b3b3b3;
    font-size: 0.75rem;
    margin: 0;
}
.contact-button {
    width: 289px;
    height: 71px;
    border-radius: 20px;
    background: var(--black);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center; /* Center text horizontally */
    align-items: center; /* Center text vertically */
    cursor: pointer;
    text-align: center; /* Center text inside flex item */
    margin-top: 15%;
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-button .text {
    color: var(--white);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 15px;
    transition: color 0.3s ease;
}

.contact-button .send-email-icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    transition: transform 0.3s ease;
}

/* Hover Effect */
.contact-button:hover {
    background: var(--black);
    transform: scale(1.05); /* Slight scaling effect */
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5); /* Enhanced shadow */
}

.contact-button:hover .text {
    color: var(--white); /* Invert text color on hover */
}

.contact-button:hover .send-email-icon {
    transform: translateY(-2px); /* Slight upward movement */
}

